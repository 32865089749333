export const partnerIntegrations = [
  {
    id: 0,
    img: '/img/partner-integration/shopify.png',
    name: 'Shopify',
    link: 'https://www.cashfree.com/shopify-payment-gateway-india/',
    desc:
      'Shopify powers millions of businesses worldwide with an all-in-one commerce platform to start, run and grow a business. To know more about how to connect Cashfree payments to your Shopify store.',
  },
  {
    id: 1,
    img: '/img/partner-integration/wix.png',
    name: 'Wix',
    link: 'https://www.cashfree.com/wix-payment-gateway/',
    desc:
      'Wix’s powerful technology allows everyone to get online with a personalized, professional web presence. To know more about how you can use Cashfree to accept payments online for your Wix store.',
  },
  {
    id: 2,
    img: '/img/partner-integration/zoho.png',
    name: 'Zoho Books',
    link:
      'https://marketplace.zoho.com/app/books/cashfree-payments-for-zoho-books',
    desc:
      'Zoho Books is a comprehensive cloud accounting platform that takes care of your business finance. To know more about how you can use Cashfree to accept payments online on Zoho books.',
  },
  {
    id: 3,
    img: '/img/partner-integration/outdocart.png',
    name: 'Outdo Cart',
    link: 'https://outdocart.com/?utm_source=cashfree',
    desc:
      'OutdoCart is an Ecommerce platform made in India for Indian Businesses, with robust support in local languages.',
  },
  // {
  //   id: 4,
  //   img: '/img/partner-integration/etsy.png',
  //   name: 'Etsy',
  //   link: '!#',
  //   desc:
  //     'WooCommerce is a customizable, open-source eCommerce platform built on WordPress',
  // },
  {
    id: 5,
    img: '/img/partner-integration/woo.png',
    name: 'Woo Commerce',
    link: 'https://wordpress.org/plugins/cashfree/',
    desc:
      'WooCommerce is a customizable, open-source eCommerce platform built on WordPress',
  },
];

// export const offers = [
//   {
//     id: 1,
//     discount: '25% off',
//     name: 'Moon Product',
//     img: '/img/partner-integration/moon-product.png',
//     desc:
//       'Empower your customers to receive payments in USD, GBP, EUR, and CAD via our local receiving account',
//     linkText: 'Get Offer',
//   },
//   {
//     id: 2,
//     discount: '₹5,000 Off',
//     name: 'Primary Infotech',
//     img: '/img/partner-integration/primary-infotech.png',
//     desc:
//       'Empower your customers to receive payments in USD, GBP, EUR, and CAD via our local receiving account',
//     linkText: 'Book a Demo Now!',
//   },
//   {
//     id: 3,
//     discount: '50% Off',
//     name: 'UBM Infotech',
//     img: '/img/partner-integration/ubm-infotech.png',
//     desc:
//       'Empower your customers to receive payments in USD, GBP, EUR, and CAD via our local receiving account',
//     linkText: 'Get Offer',
//   },
//   {
//     id: 4,
//     discount: '20% Cashback',
//     name: 'WeWork',
//     img: '/img/partner-integration/wework.png',
//     desc:
//       'Empower your customers to receive payments in USD, GBP, EUR, and CAD via our local receiving account',
//     linkText: 'Book a Demo Now!',
//   },
//   {
//     id: 5,
//     discount: '₹5,000 Off',
//     name: 'MYHQ',
//     img: '/img/partner-integration/myhq.png',
//     desc:
//       'Empower your customers to receive payments in USD, GBP, EUR, and CAD via our local receiving account',
//     linkText: 'Get Offer',
//   },
//   {
//     id: 6,
//     discount: '3 Months',
//     name: 'Payopt Credit',
//     img: '/img/partner-integration/payopt.png',
//     desc:
//       'Empower your customers to receive payments in USD, GBP, EUR, and CAD via our local receiving account',
//     linkText: 'Book a Demo Now!',
//   },
//   {
//     id: 7,
//     discount: '₹5,000 Off',
//     name: 'Sysarc Infomatix',
//     img: '/img/partner-integration/sysarc.png',
//     desc:
//       'Empower your customers to receive payments in USD, GBP, EUR, and CAD via our local receiving account',
//     linkText: 'Get Offer',
//   },
//   {
//     id: 8,
//     discount: '25% Off',
//     name: 'Apiria',
//     img: '/img/partner-integration/apiria.png',
//     desc:
//       'Empower your customers to receive payments in USD, GBP, EUR, and CAD via our local receiving account',
//     linkText: 'Book a Demo Now!',
//   },
//   {
//     id: 9,
//     discount: '50% Off',
//     name: 'Mosambi',
//     img: '/img/partner-integration/mosambi.png',
//     desc:
//       'Empower your customers to receive payments in USD, GBP, EUR, and CAD via our local receiving account',
//     linkText: 'Get Offer',
//   },
// ];
export const offers = [
  {
    id: 1,
    discount: 'GET1000',
    name: 'Shipway One',
    img: '/img/partner-integration/shipway-one.png',
    desc:
      'Get Rs.1,000 Cashback on a Wallet Recharge of Rs.1000. Limited time period offer.',
    linkText: 'Book a Demo Now!',
    link: 'https://app.shipway.com/demo?demo_request_from=cashfree1000',
  },
  {
    id: 2,
    discount: 'First month free',
    name: 'Legodesk',
    img: '/img/partner-integration/legodesk.png',
    desc:
      'Legodesk digitizes debt collections and recoveries for Lenders and Businesses and automates the journey end-to-end to manage delinquent customers.',
    link: 'https://cashfreepayments.typeform.com/to/c0loNjbh',
    linkText: 'Get Offer',
  },
];
