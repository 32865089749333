import React, { useRef } from 'react';
import GetStarted from '../components/GetStarted';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import TransparentVideo from '../components/TransparentVideo/TransparentVideo';
import { offers, partnerIntegrations } from '../content/partner-integration';
import '../styles/partner-integration.scss';

function PartnerPage(rest) {
  const container = useRef();
  return (
    <Layout
      lightHeader
      {...rest}
      signInLink="https://merchant.cashfree.com/merchants/login?source-action=Partner%20Integration&action=Sign%20In&button-id=SignIn_Navbar"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=Partner%20Integration&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="Cashfree Partner Integration - Streamline Your Payment Process"
        description="Simplify your payment process with Cashfree Partner Integration. Integrate with our reliable and secure platform to enable faster transactions, automatic reconciliation, and seamless payouts. Sign up now!"
        url="https://www.cashfree.com/partner-integration/"
        keywords={['Cashfree Partner Integration']}
      />
      <section className=" pt-[100px] pb-[75px] md:py-[120px] bg-cf-dark text-white relative partner-int-hero overflow-hidden">
        <div className="container position-relative z-10">
          <div className="flex flex-wrap justify-start lg:justify-between">
            <div className="w-full lg:w-[40%] pt-0 lg:self-center relative z-[1]">
              <p className="text-sm font-semibold text-white pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
                Partner Ecosystem
              </p>
              <h1 className="mb-3 md:mb-5 text-xl md:text-2xl font-semibold max-w-[600px] font-heading">
                Cashfree Payments Partner Eco-system
              </h1>
              <div className="text-opacity-80 max-w-[550px] text-[16px] leading-[24px] md:text-2.5md  font-body">
                Explore this space for amazing offers on our partner&nbsp;apps
              </div>
            </div>
            <div className="md:w-full lg:w-[60%] lg:pl-[19px] pt-[80px] md:pt-0 md:flex justify-center h-full items-center grow-container-md">
              <TransparentVideo
                backgroundRef={container}
                className="relative z-10 "
                poster="/img/partner-integration/poster.png"
                width="1440"
                height="1200"
                mp4Src="/img/partner-integration/hero-video.mp4"
                webmSrc="/img/partner-integration/hero-video.webm"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="p-section-2 relative overflow-hidden ">
        <div className="container">
          <div className="mb-5 md:mb-14">
            <div className="mb-3 md:mb-5 w-full md:w-1/2 self-center text-shs md:text-shl font-heading font-semibold">
              Platform Partners
            </div>
            <div className="text-cf-hero max-w-[550px] text-[14px] leading-[24px] md:text-[15px] font-body">
              Hassle-free intergations with all leading plug-ins & platforms
            </div>
          </div>
          <div className="flex flex-wrap -mx-[8px] md:-mx-3.5">
            {partnerIntegrations.map(({ id, img, name, desc, link }) => (
              <div key={id} className="w-full p-2 md:p-3.5 md:w-1/3">
                <a
                  href={link}
                  className="flex flex-col justify-between bg-cf-light-grey p-6 md:p-8 rounded h-full group transition-all duration-300 md:hover:shadow-card focus:shadow-card md:min-h-[286px]">
                  <span>
                    <div className="flex flex-wrap  items-center">
                      <img
                        src={img}
                        alt="shopify"
                        className="w-12 h-12 rounded-[4.8px]"
                      />
                      <span className="pl-3 font-semibold text-[16px] text-cf-hero">
                        {name}
                      </span>
                    </div>
                    <p className="py-3 md:py-5 text-cf-hero text-2.5sm md:text-base">
                      {desc}
                    </p>
                  </span>
                  <div className="button button-unstyled btn p-0 !text-cf-green left-[83px] !right-auto">
                    Connect
                    <svg
                      className="!w-[6px] !h-[14px] ml-2.5 group-hover:translate-x-[3px] transition-transform duration-300"
                      width="6"
                      height="11"
                      viewBox="0 0 6 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1 1L5 5.5L1 10"
                        stroke="#05C16E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="p-section-2 bg-cf-light-grey relative overflow-hidden offers-program">
        <div className="container">
          <div className="text-shs text-center md:text-left mx-auto md:ml-0 md:max-w-[610px] md:text-shl font-semibold mb-8 md:mb-16  font-heading">
            Partner offers curated to you
          </div>
          <div className="flex flex-wrap -mx-[8px] md:-mx-3.5">
            {offers.map(({ id, discount, name, img, desc, linkText, link }) => (
              <div
                key={id}
                className="w-full pt-0 md:pt-0 p-2 md:p-3.5 md:w-1/2 lg:w-1/3">
                <a
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-cf-white block  rounded overflow-hidden coupon-card group h-full">
                  <div className="flex relative coupon-wrapper h-full">
                    <div className="relative self-center">
                      <p className="rotate-180 discount text-cf-primary font-bold text-md md:text-3md">
                        {discount}
                      </p>
                    </div>
                    <div className=" pl-[34px] pr-[28px] py-8 md:pl-[40px] md:pr-[32px] md:pt-3 md:pb-4 border-l-[1.6px] border-l-cf-stroke border-dashed offer-container">
                      <div className="flex flex-wrap md:flex-nowrap items-center">
                        <img
                          src={img}
                          alt="Moon Product"
                          className="w-[40px] md:w-[48px] h-[40px] md:h-[48px] rounded-full"
                        />
                        <span className="pl-3 font-semibold text-2.5sm md:text-2md text-cf-hero">
                          {name}
                        </span>
                      </div>
                      <p className="pt-4 md:pt-5 text-cf-hero text-tiny  md:text-2.5sm md:leading-6">
                        {desc}
                      </p>
                      <div className="button button-unstyled btn p-0 !text-cf-green left-[83px] !right-auto text-left mt-4 text-2.5sm">
                        {linkText}
                        <svg
                          className="!w-[6px] !h-[14px] ml-2.5 group-hover:translate-x-[3px] transition-transform duration-300"
                          width="6"
                          height="11"
                          viewBox="0 0 6 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M1 1L5 5.5L1 10"
                            stroke="#05C16E"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>

      <GetStarted
        startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=Partner%20Integration&action=Sign%20Up&button-id=StartNow_Footer"
        getInTouchLink="https://www.cashfree.com/contact-sales?source-action=Partner%20Integration&action=Contact%20Sales&button-id=ContactSales_Footer"
      />
    </Layout>
  );
}

export default PartnerPage;
